import React, { useState } from 'react';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
	CRow,
	CCol,
	CCard,
	CCardBody,
	CCardHeader,
	CDataTable,
	CBadge,
	CButton,
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
} from '@coreui/react';
import { capitalize, errorWithCode, extractErrorMessage } from '../../helpers';
import { updateCustomer, loadCustomerWallets, disableWallet, enableWallet } from '../../services/BackendService';
import AddWalletModal from './AddWalletModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import CustomerWalletPopup from './CustomerWalletPopup';
import Balance from './CustomerWalletBalance';
import Credit from './CustomerWalletCredit';
import { IWallet, WalletStatus } from '../wallets/types';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../helpers/customHooks';
import { Permission } from '../../reducers/userReducer';
import WalletTransferModal from './WalletTransferModal';
import toast from 'react-hot-toast';
import { TradingType } from './types';

function CustomerWalletsTable({ customerId }: { customerId: string }) {
	const [walletPopup, setWalletPopup] = useState<IWallet | null>(null);
	const [addWalletModalShow, toggleAddWalletVisibility] = useState<boolean>(false);
	const [walletIdToEnable, setWalletIdToEnable] = useState<number | null>(null);
	const [walletIdToDisable, setWalletIdToDisable] = useState<number | null>(null);
	const [transferModalShow, setTransferModalShow] = useState<boolean>(false);

	const permissions = useAppSelector((state) => state.user.permissions);

	const { data, isLoading } = useQuery(['customer-wallets', customerId], async () => {
		const data = await loadCustomerWallets(customerId);
		return data;
	});

	const queryClient = useQueryClient();

	const manageWalletsPermission = permissions?.includes(Permission.MANAGE_WALLETS);

	const showTransferModal = () => {
		setTransferModalShow(true);
	};

	const hideTransferModal = () => {
		setTransferModalShow(false);
	};

	const walletsLengthLimit = data ? data.filter((w) => !w.isDemo).length : 0;

	const enableWalletMutation = useMutation(['enable-wallet'], (id: number) => enableWallet(id), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('customer-wallets');
			setWalletIdToEnable(null);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
	});

	const disableWalletMutation = useMutation(['enable-wallet'], (id: number) => disableWallet(id), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('customer-wallets');
			setWalletIdToDisable(null);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
	});

	const tryEnableWallet = () => {
		if (walletIdToEnable) {
			enableWalletMutation.mutate(walletIdToEnable);
		}
	};

	const tryDisableWallet = () => {
		if (walletIdToDisable) {
			disableWalletMutation.mutate(walletIdToDisable);
		}
	};

	return (
		<CRow>
			<CCol md={12}>
				<CCard className="card card--listing">
					<CCardBody className="card__holder">
						<CCardHeader className="card__header">
							<div className="card__info">
								<h4 className="card__title">{_t('customer.wallets.title')}</h4>
							</div>
							{manageWalletsPermission && (
								<div>
									{walletsLengthLimit > 1 && (
										<CButton
											color="primary"
											className="mr-2 header-button-transfer"
											onClick={showTransferModal}
											title={_t('customer.transfer.transfer')}
										/>
									)}
									<CButton
										color="primary"
										className="mr-2 header-button-add"
										onClick={() => toggleAddWalletVisibility(true)}
									/>
								</div>
							)}
						</CCardHeader>
						<CDataTable
							loading={isLoading}
							items={data || []}
							fields={[
								{ key: 'status', label: capitalize(_t('global.status')) },
								{ key: 'isDemo', label: _t('global.type') },
								{ key: 'info', label: capitalize(_t('customer.wallets.accountId')) },
								{ key: 'currency', label: capitalize(_t('customer.wallets.currency')) },
								{ key: 'balance', label: capitalize(_t('customer.wallets.balance')) },
								{ key: 'credit', label: capitalize(_t('customer.wallets.credit')) },
								{ key: 'group', label: capitalize(_t('wallet-modal.group')) },
								{ key: 'tradingType', label: _t('wallets.trading-type') },
								{ key: 'actions', label: null },
							]}
							scopedSlots={{
								info: (wallet: IWallet) => (
									<td>
										{manageWalletsPermission && (
											<Link to={`/wallets/${wallet.id}`}>
												<div>{wallet.id}</div>
												<div className="small text-medium-emphasis">{wallet.type}</div>
											</Link>
										)}
										{!manageWalletsPermission && (
											<>
												<div>{wallet.id}</div>
												<div className="small text-medium-emphasis">{wallet.type}</div>
											</>
										)}
									</td>
								),
								tradingType: (wallet: IWallet) => (
									<td>
										<CBadge
											style={{ backgroundColor: wallet.tradingType === TradingType.Netting ? '#18B8C2' : '#9618C2' }}
										>
											{wallet.tradingType === TradingType.Netting ? _t('wallets.netting') : _t('wallets.hedging')}
										</CBadge>
									</td>
								),
								isDemo: (wallet: IWallet) => (
									<td>
										<CBadge color={wallet.isDemo ? 'danger' : 'success'}>
											{wallet.isDemo ? _t('global.demo') : _t('global.real')}
										</CBadge>
									</td>
								),
								balance: (wallet: IWallet) => (
									<td style={{ width: '10%' }}>
										{wallet.status === 'ACTIVE' && <Balance walletId={wallet.id} />}
										{wallet.status !== 'ACTIVE' && 'n/a'}
									</td>
								),
								credit: (wallet: IWallet) => (
									<td style={{ width: '10%' }}>
										{wallet.status === 'ACTIVE' && <Credit walletId={wallet.id} />}
										{wallet.status !== 'ACTIVE' && 'n/a'}
									</td>
								),
								group: (wallet: IWallet) => <td>{wallet.group?.name}</td>,
								status: (wallet: IWallet) => (
									<td>
										<CBadge color={wallet.status.toLowerCase()}>{wallet.status}</CBadge>
										{wallet.primary && <br />}
										{wallet.primary && <CBadge color="primary">PRIMARY</CBadge>}
									</td>
								),
								actions: (wallet: IWallet) => (
									<td>
										{manageWalletsPermission && (
											<CDropdown>
												<CDropdownToggle size="md" color="primary" caret={false}>
													<CIcon name="cil-pencil" size="sm" />
												</CDropdownToggle>
												<CDropdownMenu className="py-0" placement="bottom-end">
													<CDropdownItem onClick={() => setWalletPopup(wallet)}>{_t('action.edit')}</CDropdownItem>

													{wallet.status === WalletStatus.Active && (
														<CDropdownItem onClick={() => setWalletIdToDisable(wallet.id)}>
															{_t('wallet.disable-wallet')}
														</CDropdownItem>
													)}

													{wallet.status === WalletStatus.Disabled && (
														<CDropdownItem onClick={() => setWalletIdToEnable(wallet.id)}>
															{_t('wallet.enable-wallet')}
														</CDropdownItem>
													)}
												</CDropdownMenu>
											</CDropdown>
										)}
									</td>
								),
							}}
							hover
							striped
						/>
					</CCardBody>
				</CCard>
			</CCol>

			{manageWalletsPermission && (
				<AddWalletModal
					show={addWalletModalShow}
					onClose={() => toggleAddWalletVisibility(false)}
					customerId={customerId}
				/>
			)}

			{walletPopup && <CustomerWalletPopup wallet={walletPopup} onClose={() => setWalletPopup(null)} />}

			{walletsLengthLimit > 1 && (
				<WalletTransferModal show={transferModalShow} onClose={hideTransferModal} customerId={customerId} />
			)}

			<ConfirmationModal
				id=""
				show={walletIdToEnable !== null}
				onConfirm={tryEnableWallet}
				hidePanel={() => setWalletIdToEnable(null)}
				title={_t('wallet.enable-wallet')}
				error={null}
				buttonColor="primary"
				withMutation={false}
				isLoading={false}
			/>

			<ConfirmationModal
				id=""
				show={walletIdToDisable !== null}
				onConfirm={tryDisableWallet}
				hidePanel={() => setWalletIdToDisable(null)}
				title={_t('wallet.disable-wallet')}
				error={null}
				buttonColor="primary"
				withMutation={false}
				isLoading={false}
			/>
		</CRow>
	);
}

export default CustomerWalletsTable;
