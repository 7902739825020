import { TradingType } from "../customers/types";
import { IGroup } from "../groups/types";

export const currencies = [{ label: 'USD', value: 'USD' }];

export const types = [
	{ label: 'Demo', value: 'DEMO' },
	{ label: 'Real', value: 'REAL' },
];


export const tradingTypesOptions = [
	{ label: 'Hedging', value: TradingType.Hedging },
	{ label: 'Netting', value: TradingType.Netting },
]

export enum WalletStatus {
	Active = 'ACTIVE',
	Disabled = 'DISABLED',
}

export enum PropChallengeAccountStatus {
	Active = 'ACTIVE',
	Failed = 'FAILED',
	Passed = 'PASSED',
}
export interface IWallet {
	id: number;
	type: string;
	currency: string;
	status: WalletStatus;
	primary: boolean;
	group: IGroup;
	leverage: number;
	customer?: {
		name: string;
		id: string;
	};
	isDemo: boolean;
	tradingType: TradingType;
}

export interface IWalletWithBalance extends IWallet {
	equity: string;
	margin: string;
	marginFree: string;
	profitLoss: string | undefined;
	balance: string;
	credit: string;
}

export interface IWalletPerformanceRecord {
	day: string;
	balance: string | null;
	credit: string | null;
}

export interface IWalletQuery {
	wallet: IWalletWithBalance;
	performance: Array<IWalletPerformanceRecord>;
}

export interface IWalletsQuery {
	pages: number;
	wallets: Array<IWalletWithBalance>;
}

export interface IRoutingAdapter {
	id: number;
	name: string;
}
