import React, { useState } from 'react';
import {
	CRow,
	CCol,
	CCard,
	CCardBody,
	CCardHeader,
	CBadge,
	CButton,
	CModalHeader,
	CModal,
	CModalBody,
	CTooltip,
	CModalTitle,
} from '@coreui/react';
import { formatDateTime } from '../../../helpers';
import { Link } from 'react-router-dom';
import _t from 'counterpart';
import { ICallDetail, ICommunication, IEmailDetail, ITableField, IMessageDetail } from '../types';
import DOMPurify from 'dompurify';
import PaginationTable from '../../../components/PaginationTable';

const CommunicationTypeBadge = ({ type }: { type: string }) => {
	const colors: any = {
		CALL: 'success',
		EMAIL: 'danger',
		MESSAGE: 'primary',
	};

	return <CBadge color={colors[type]}>{type}</CBadge>;
};

const CallDetail = (detail: ICallDetail) => {
	const { answered, recordingFileLink } = detail;

	return (
		<div className="d-flex align-items-center justify-content-between">
			<div>
				{/* eslint-disable jsx-a11y/media-has-caption */}
				{answered && (
					<audio controls>
						<source src={recordingFileLink} type="audio/wav" />
					</audio>
				)}
			</div>
			{answered === null && (
				<div className="badge-outline-secondary">
					<span>{_t('customer.communication.unknown')}</span>
				</div>
			)}
			{answered === false && (
				<div className="badge-outline-danger">
					<span>{_t('customer.communication.not-answered')}</span>
				</div>
			)}
			{answered === true && (
				<div className="badge-outline-success">
					<span className="mr-1">{_t('customer.communication.answered')}</span>
				</div>
			)}
		</div>
	);
};

const MessageDetail = (detail: IMessageDetail) => {
	if (detail) {
		const { readAt, subject } = detail;

		return (
			<div className="d-flex align-items-center justify-content-between">
				<span className="ml-1">{subject}</span>
				{!readAt && (
					<div className="badge-outline-danger">
						<span>{_t('customer.communication.not-read')}</span>
					</div>
				)}
				{readAt && (
					<CTooltip content={formatDateTime(readAt)}>
						<div className="badge-outline-success">
							<span>{_t('customer.communication.read')}</span>
						</div>
					</CTooltip>
				)}
			</div>
		);
	}
};

interface IProps {
	fields: Array<ITableField>;
	data: Array<ICommunication> | null;
	pages?: number;
	page?: number;
	onPageChanged?: (page: number) => void;
	isLoading?: boolean;
}

const CommunicationsTable = ({ data, fields, pages, page, onPageChanged, isLoading }: IProps) => {
	const [emailDetails, setEmailDetails] = useState<IEmailDetail | null>(null);
	const [messageDetails, setMessageDetails] = useState<IMessageDetail | null>(null);

	const showEmailModal = (detail: IEmailDetail) => {
		setEmailDetails(detail);
	};

	const hideEmailModal = () => {
		setEmailDetails(null);
	};

	const showMessageModal = (detail: IMessageDetail) => {
		setMessageDetails(detail);
	};

	const hideMessageModal = () => {
		setMessageDetails(null);
	};

	const viaPushBadge = (details: IMessageDetail) => {
		if (details?.viaPush) {
			return <CBadge color="success">{_t('customer.communication.via-push')}</CBadge>;
		}
		return '';
	};

	const scopedSlots = React.useMemo(
		() => ({
			date: (communication: ICommunication) => <td>{formatDateTime(communication.date)}</td>,
			from: (communication: ICommunication) => (
				<td>
					<Link to={`/customers/${communication.fromCustomerId}`}>
						<div>{communication.fromName}</div>
					</Link>
				</td>
			),
			to: (communication: ICommunication) => (
				<td>
					{communication.userType === 'customer' && (
						<Link to={`/customers/${communication.toCustomerId}`}>
							<div>{communication.toName}</div>
						</Link>
					)}
					{communication.userType === 'lead' && (
						<Link to={`/leads/${communication.id}`}>
							<div>{communication.toName}</div>
						</Link>
					)}
				</td>
			),
			type: (communication: ICommunication) => (
				<td>
					<div className="small text-medium-emphasis">
						<CommunicationTypeBadge type={communication.type} />
					</div>
				</td>
			),
			detail: (communication: ICommunication) => (
				<td>
					{communication.type === 'CALL' && CallDetail(communication.detail as ICallDetail)}
					{communication.type === 'MESSAGE' && MessageDetail(communication.detail as IMessageDetail)}
					{communication.type === 'MESSAGE' && viaPushBadge(communication.detail as IMessageDetail)}
				</td>
			),
			actions: (communication: ICommunication) => (
				<td>
					{communication.type === 'MESSAGE' && (
						<CButton
							className="communications-table-actions-button"
							onClick={() => showMessageModal(communication.detail as IMessageDetail)}
							color="primary"
						>
							{_t('customer.communication.show-message')}
						</CButton>
					)}
					{communication.type === 'EMAIL' && (
						<CButton
							className="communications-table-actions-button"
							onClick={() => showEmailModal(communication.detail as IEmailDetail)}
							color="primary"
						>
							{_t('customer.communication.show-email')}
						</CButton>
					)}
				</td>
			),
		}),
		[]
	);

	return (
		<CRow>
			<CCol md={12}>
				<CCard className="card card--listing">
					<CCardBody className="card__holder">
						<CCardHeader className="card__header">
							<div className="card__info">
								<h4 className="card__title">{_t('customer.communication.title')}</h4>
							</div>
						</CCardHeader>
						<PaginationTable
							data={data}
							tableFields={fields}
							scopedSlots={scopedSlots}
							pages={pages || 0}
							pagination
							activePage={page}
							onPageChanged={onPageChanged}
							loading={Boolean(isLoading)}
						/>
					</CCardBody>
				</CCard>
			</CCol>
			{emailDetails && (
				<CModal show={Boolean(emailDetails)} onClose={hideEmailModal} size="xl">
					<CModalHeader closeButton>
						<CModalTitle>{emailDetails.subject}</CModalTitle>
					</CModalHeader>
					<CModalBody>
						<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(emailDetails.body) }} />
					</CModalBody>
				</CModal>
			)}

			<CModal show={Boolean(messageDetails)} onClose={hideMessageModal} size="lg">
				<CModalHeader>
					<CModalTitle>{messageDetails?.subject}</CModalTitle>
				</CModalHeader>
				<CModalBody className="message-details">{messageDetails?.text}</CModalBody>
			</CModal>
		</CRow>
	);
};

export default React.memo(CommunicationsTable);
