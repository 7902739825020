import React from 'react';
import { CBadge } from '@coreui/react';

const AffectedTypeBadge = ({ type }: { type: string }) => {
	const colors: any = {
		LEAD: 'success',
		CUSTOMER: 'primary',
		INSTRUMENT: 'warning',
		CURRENCY_CONVERSION: 'secondary',
		COMMISSION_LEDGER_RECORD: 'dark',
		COMMISSION_PAYOUT_REQUEST: 'info',
		POSITION: 'warning',
		ORDER: 'warning',
		WALLET: 'primary',
		PROPTRADING: 'light',
	};

	return <CBadge color={colors[type]}>{type}</CBadge>;
};

export default AffectedTypeBadge;
