import React, { useState, useEffect, FormEvent } from 'react';
import {
	CCard,
	CCardBody,
	CRow,
	CCol,
	CForm,
	CFormGroup,
	CLabel,
	CButton,
	CInputGroup,
	CInput,
	CSwitch,
} from '@coreui/react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import _t from 'counterpart';
import IPropChallenge, { ICreatePropChallengeFormData, IUpdatePropChallengeFormData } from './types';
import {
	updatePropTradingChallenge,
	createPropTradingChallenge,
	getPropTradingChallenge,
} from '../../services/BackendService';
import CustomSelect from '../../components/CustomSelect';
import { findErrorFromValidation, errorWithCode } from '../../helpers';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import PageLayout from '../../components/PageLayout';
import toast from 'react-hot-toast';
import { ChallengeGroupsSelector } from './helperComponents';

const propAccountTypes = [
	{ value: 'REGULAR', label: 'REGULAR' },
	{ value: 'SWING', label: 'SWING' },
];

const propAccountStatuses = [
	{ value: 'ACTIVE', label: 'ACTIVE' },
	{ value: 'DISABLED', label: 'DISABLED' },
	{ value: 'DISCONTINUED', label: 'DISCONTINUED' },
];

const CreateUpdatePropChallengePage = () => {
	const history = useHistory();
	const searchParams = new URLSearchParams(history.location.search);
	const editParam = searchParams.get('edit');

	const isEditing = editParam !== null;
	const challengeId = editParam ? decodeURIComponent(editParam!) : null;
	const [formState, setFormState] = useState<ICreatePropChallengeFormData | IUpdatePropChallengeFormData>({
		id: undefined,
		title: undefined,
		fee: undefined,
		refundable: false,
		level: undefined,
		nextChallengeId: null,
		secondChanceAllowed: false,
		profitSplitFactor: undefined,
		leverage: undefined,
		initialBalance: undefined,
		minTradingDays: undefined,
		maxTradingDays: undefined,
		maxInactivityDays: undefined,
		profitTarget: undefined,
		maxDailyDrawdown: undefined,
		maxTotalDrawdown: undefined,
		isDemoAccount: false,
		propAccountType: propAccountTypes[0].value,
		accountGroup: undefined,
		accountCurrency: undefined,
		status: propAccountStatuses[0].value,
	});

	const onError = (error: any) => {
		if (error) {
			const { message, code } = errorWithCode(error);

			if (code !== 422) {
				toast.error(message);
			}
		}
	};

	const createChallengeMutation = useMutation(
		(challenge: ICreatePropChallengeFormData) => createPropTradingChallenge(challenge),
		{
			onSuccess: () => {
				toast.success(_t('prop-challenges.challenge-created-successfully'));
				history.push('/prop-challenges');
			},
			onError,
			retry: false,
		}
	);

	const updateChallengeMutation = useMutation(
		(challenge: IUpdatePropChallengeFormData) => updatePropTradingChallenge(challenge, challengeId!),
		{
			onSuccess: () => {
				toast.success(_t('prop-challenges.challenge-updated-successfully'));
				history.push('/prop-challenges');
			},
			onError,
			retry: false,
		}
	);

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(createChallengeMutation.error || updateChallengeMutation.error, paramName);
		return error;
	};

	const { data: propChallenge } = useQuery<IPropChallenge | null, Error>(
		[`prop-challenge-${challengeId ?? 'unknown'}`],
		async () => {
			if (challengeId) {
				const data = await getPropTradingChallenge(challengeId);
				return data;
			}
			return null;
		},
		{
			onError,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (propChallenge) {
			setFormState({ ...propChallenge });
		}
	}, [propChallenge]);

	const createOrUpdatePropChallenge = (e: React.FormEvent) => {
		e.preventDefault();
		if (isEditing) {
			const mutationData = {
				...formState,
				fee: Number(formState.fee),
				level: Number(formState.level),
				profitSplitFactor: Number(formState.profitSplitFactor),
				leverage: Number(formState.leverage),
				initialBalance: formState.initialBalance,
				minTradingDays: Number(formState.minTradingDays),
				maxTradingDays: Number(formState.maxTradingDays),
				maxInactivityDays: Number(formState.maxInactivityDays),
				profitTarget: formState.profitTarget,
				maxDailyDrawdown: formState.maxDailyDrawdown,
				maxTotalDrawdown: formState.maxTotalDrawdown,
			};
			updateChallengeMutation.mutate(mutationData);
		} else {
			const mutationData = {
				...(formState as ICreatePropChallengeFormData),
				fee: Number(formState.fee),
				level: Number(formState.level),
				profitSplitFactor: Number(formState.profitSplitFactor),
				leverage: Number(formState.leverage),
				initialBalance: formState.initialBalance!,
				minTradingDays: Number(formState.minTradingDays),
				maxTradingDays: Number(formState.maxTradingDays),
				maxInactivityDays: Number(formState.maxInactivityDays),
				profitTarget: formState.profitTarget!,
				maxDailyDrawdown: formState.maxDailyDrawdown!,
				maxTotalDrawdown: formState.maxTotalDrawdown!,
			};
			createChallengeMutation.mutate(mutationData);
		}
	};

	const handleInputChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const value = target.value!;

		setFormState({
			...formState,
			[name]: value,
		});
	};

	const onSelectChange = (newValue: any) => {
		if (newValue !== formState.accountGroup) {
			setFormState({ ...formState, accountGroup: newValue });
		}
	};

	const toggleSecondChance = () => {
		setFormState({ ...formState, secondChanceAllowed: !formState.secondChanceAllowed });
	};

	const toggleIsDemoAccount = () => {
		setFormState({ ...formState, isDemoAccount: !formState.isDemoAccount });
	};

	const toggleIsRefundable = () => {
		setFormState({ ...formState, refundable: !formState.refundable });
	};

	const onAccountTypeChange = (newValue: any) => {
		setFormState({ ...formState, propAccountType: newValue.value });
	};

	const onAccountStatusChange = (newValue: any) => {
		setFormState({ ...formState, status: newValue.value });
	};

	const loading = createChallengeMutation.isLoading || updateChallengeMutation.isLoading;
	const currentPropAccountType = propAccountTypes.find((pat) => pat.value === formState.propAccountType);
	const currentPropAccountStatus = propAccountStatuses.find((pas) => pas.value === formState.status);
	const getTitle = () => {
		if (!challengeId) {
			return _t.translate('prop-challenges.create-challenge');
		}
		return _t.translate('prop-challenges.edit-challenge');
	};

	const title = getTitle();

	return (
		<PageLayout title={title}>
			<CForm onSubmit={createOrUpdatePropChallenge}>
				<CRow>
					<CCol>
						<CCard>
							<CCardBody>
								{!isEditing && (
									<div>
										{' '}
										<CFormGroup>
											<CLabel htmlFor="id">{_t(`prop-challenges.id`)}</CLabel>
											<CInputGroup>
												<CInput
													type="text"
													id={'id'}
													name={'id'}
													placeholder={'VIPPRO'}
													value={(formState as ICreatePropChallengeFormData).id || ''}
													onChange={handleInputChange}
													disabled={false}
													className="instrument-input-height"
												/>
											</CInputGroup>
										</CFormGroup>
									</div>
								)}
								<CFormGroup>
									<CLabel htmlFor="title">{_t(`prop-challenges.title`)}</CLabel>
									<CInputGroup>
										<CInput
											type="text"
											id={'title'}
											name={'title'}
											placeholder={'First VIP Challenge'}
											value={formState.title || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="fee">{_t(`prop-challenges.fee`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'fee'}
											name={'fee'}
											placeholder={'50'}
											value={formState.fee || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="level">{_t(`prop-challenges.level`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'level'}
											name={'level'}
											placeholder={'1'}
											value={formState.level || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="next-challenge">{_t(`prop-challenges.next-challenge-id`)}</CLabel>
									<CInputGroup>
										<CInput
											type="text"
											id={'next-challenge'}
											name={'nextChallengeId'}
											placeholder={'VIPPRO2'}
											value={formState.nextChallengeId || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="profit-split-factor">{_t(`prop-challenges.profit-split-factor`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'profit-split-factor'}
											name={'profitSplitFactor'}
											placeholder={'0.08'}
											value={formState.profitSplitFactor || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="leverage">{_t(`prop-challenges.leverage`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'leverage'}
											name={'leverage'}
											placeholder={'1'}
											value={formState.leverage || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="initial-balance">{_t(`prop-challenges.initial-balance`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'initial-balance'}
											name={'initialBalance'}
											placeholder={'200'}
											value={formState.initialBalance || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="minimal-trading-days">{_t(`prop-challenges.min-trading-days`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'minimal-trading-days'}
											name={'minTradingDays'}
											placeholder={'1'}
											value={formState.minTradingDays || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="maximal-trading-days">{_t(`prop-challenges.max-trading-days`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'maximal-trading-days'}
											name={'maxTradingDays'}
											placeholder={'5'}
											value={formState.maxTradingDays || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="maximal-inactivity-days">{_t(`prop-challenges.max-inactivity-days`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'maximal-inactivity-days'}
											name={'maxInactivityDays'}
											placeholder={'1'}
											value={formState.maxInactivityDays || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="profit-target">{_t(`prop-challenges.profit-target-factor`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'profit-target'}
											name={'profitTarget'}
											placeholder={'1.5'}
											value={formState.profitTarget || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="maximal-daily-drawdown">{_t(`prop-challenges.max-daily-drawdown`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'maximal-daily-drawdown'}
											name={'maxDailyDrawdown'}
											placeholder={'0.7'}
											value={formState.maxDailyDrawdown || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="maximal-total-drawdown">{_t(`prop-challenges.max-total-drawdown`)}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id={'maximal-total-drawdown'}
											name={'maxTotalDrawdown'}
											placeholder={'3.7'}
											value={formState.maxTotalDrawdown || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
								</CFormGroup>
								{findError('accountCurrency') && (
									<CLabel className="text-danger">{findError('accountCurrency')}</CLabel>
								)}
								<CFormGroup>
									<CLabel htmlFor="nf-group">{_t('prop-challenges.account-group')}</CLabel>
									<ChallengeGroupsSelector
										value={formState.accountGroup || ''}
										onChange={onSelectChange}
										currency={formState.accountCurrency || 'USD'}
									/>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="account-currency">{_t(`prop-challenges.account-currency`)}</CLabel>
									<CInputGroup>
										<CInput
											type="text"
											id={'account-currency'}
											name={'accountCurrency'}
											placeholder={'USD'}
											value={formState.accountCurrency || ''}
											onChange={handleInputChange}
											disabled={false}
											className="instrument-input-height"
										/>
									</CInputGroup>
									{findError('accountCurrency') && (
										<CLabel className="text-danger">{findError('accountCurrency')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="propAccountType">{_t('prop-challenges.prop-account-type')}</CLabel>
									<CInputGroup>
										<CustomSelect
											className="trading-select"
											options={propAccountTypes}
											name="propAccountType"
											value={currentPropAccountType}
											whiteBackground
											onChange={onAccountTypeChange}
											fullWidth={true}
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="propAccountStatus">{_t('prop-challenges.status')}</CLabel>
									<CInputGroup>
										<CustomSelect
											className="trading-select"
											options={propAccountStatuses}
											name="propAccountStatus"
											value={currentPropAccountStatus}
											whiteBackground
											onChange={onAccountStatusChange}
											fullWidth={true}
										/>
									</CInputGroup>
								</CFormGroup>
								<CFormGroup>
									<CLabel>{_t(`prop-challenges.second-chance-allowed`)}</CLabel>
									<br></br>
									<CSwitch
										size="lg"
										shape="pill"
										color="primary"
										checked={formState.secondChanceAllowed}
										onChange={toggleSecondChance}
									/>
									<br></br>
									{findError('secondChanceAllowed') && (
										<CLabel className="text-danger">{findError('secondChanceAllowed')}</CLabel>
									)}
								</CFormGroup>

								<CFormGroup>
									<CLabel>{_t(`prop-challenges.refundable`)}</CLabel>
									<br></br>
									<CSwitch
										size="lg"
										shape="pill"
										color="primary"
										checked={formState.refundable}
										onChange={toggleIsRefundable}
									/>
									<br></br>
									{findError('refundable') && <CLabel className="text-danger">{findError('refundable')}</CLabel>}
								</CFormGroup>

								<CFormGroup>
									<CLabel>{_t(`prop-challenges.is-demo-account`)}</CLabel>
									<br></br>
									<CSwitch
										size="lg"
										shape="pill"
										color="primary"
										checked={formState.isDemoAccount}
										onChange={toggleIsDemoAccount}
									/>
									<br></br>
									{findError('is-demo-account') && (
										<CLabel className="text-danger">{findError('is-demo-account')}</CLabel>
									)}
								</CFormGroup>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
				<CRow>
					<CCol>
						<ButtonWithLoader
							isLoading={loading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={isEditing ? _t('action.update') : _t('action.save')}
							className="instrument-buttons mt-2"
						/>
					</CCol>
				</CRow>
			</CForm>
		</PageLayout>
	);
};

export default CreateUpdatePropChallengePage;
