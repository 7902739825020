import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { getPropTradingChallenges } from '../../services/BackendService';
import _t from 'counterpart';
import IPropChallenge from './types';
import BooleanBadge from '../../components/BooleanBadge';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import PageLayout from '../../components/PageLayout';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import PaginationTable from '../../components/PaginationTable';
import { formatCurrency } from '../../helpers';

const PropChallengesPage = () => {
	const history = useHistory();

	const propChallengesQuery = useQuery<Array<IPropChallenge>>(['prop-challenges'], () => getPropTradingChallenges());

	const tableFields = useMemo(
		() => [
			{ key: 'id', label: 'ID', sorter: false },
			{ key: 'title', label: _t('prop-challenges.title'), sorter: false },
			{ key: 'level', label: _t('prop-challenges.level'), sorter: false },
			{ key: 'nextChallengeID', label: _t('prop-challenges.next-challenge-id'), sorter: false },
			{ key: 'initialBalance', label: _t('prop-challenges.initial-balance'), sorter: false },
			{ key: 'fee', label: _t('prop-challenges.fee'), sorter: false },
			{ key: 'accountGroup', label: _t('prop-challenges.account-group'), sorter: false },
			{ key: 'propAccountType', label: _t('prop-challenges.prop-account-type'), sorter: false },
			{ key: 'isDemoAccount', label: _t('prop-challenges.is-demo-account'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			id: ({ id }: IPropChallenge) => <td>{id || '-'}</td>,
			title: ({ title }: IPropChallenge) => <td>{title || '-'}</td>,
			initialBalance: ({ initialBalance, accountCurrency }: IPropChallenge) => (
				<td>{formatCurrency(initialBalance, accountCurrency) || '-'}</td>
			),
			fee: ({ fee, accountCurrency }: IPropChallenge) => <td>{formatCurrency(fee, accountCurrency) || '-'}</td>,
			level: ({ level }: IPropChallenge) => <td>{level || '-'}</td>,
			nextChallengeID: ({ nextChallengeId }: IPropChallenge) => <td>{nextChallengeId || '-'}</td>,
			isDemoAccount: ({ isDemoAccount }: IPropChallenge) => (
				<td>
					<BooleanBadge value={isDemoAccount} />
				</td>
			),
			accountGroup: ({ accountGroup }: IPropChallenge) => <td>{accountGroup || '-'}</td>,
			propAccountType: ({ propAccountType }: IPropChallenge) => <td>{propAccountType || '-'}</td>,
		}),
		[]
	);

	const rowClicked = useCallback(
		(propChallenge: IPropChallenge) => {
			const { id } = propChallenge;
			history.push(`/prop-challenges/${id}`);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history.push]
	);

	const linkToCreatePage = () => {
		history.push('/prop-challenges/form');
	};

	const onErrorRetry = () => {
		propChallengesQuery.refetch();
	};

	if (propChallengesQuery.isIdle) {
		return <Loading />;
	}

	if (propChallengesQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('prop-challenges.challenges')}>
			<CRow>
				<CCol>
					<CCard style={{ overflow: 'visible' }}>
						<CCardHeader className="pb-0">
							<div className="float-right">
								<CButton className="mr-2 header-button-add" onClick={linkToCreatePage} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={propChallengesQuery.data || []}
								loading={propChallengesQuery.isLoading}
								pages={0}
								pagination
								onRowClicked={rowClicked}
								clickableRows
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default PropChallengesPage;
