import React from 'react';
import { CBadge } from '@coreui/react';
import { PropChallengeAccountStatus } from './types';

interface IColors {
	[key: string]: 'success' | 'danger' | 'warning';
}

const PropChallengeAccountStatusBadge = ({ status }: { status: PropChallengeAccountStatus }) => {
	const colors: IColors = {
		PASSED: 'success',
		FAILED: 'danger',
		ACTIVE: 'warning',
	};
	if (!(status in colors)) {
		return <CBadge color="info">{status}</CBadge>;
	}
	return <CBadge color={colors[status]}>{status}</CBadge>;
};

export default PropChallengeAccountStatusBadge;
