import { UserRole } from 'src/reducers/userReducer';
import { IDateRange } from '../../reducers/filtersReducer';

export enum AdminActivityAction {
	TransactionCreated = 'TRANSACTION_CREATED',
	CustomerDetailsUpdated = 'CUSTOMER_DETAILS_UPDATED',
	CustomerStatusUpdated = 'CUSTOMER_STATUS_UPDATED',
	LeadDetailsUpdated = 'LEAD_DETAILS_UPDATED',
	LeadReassigned = 'LEAD_REASSIGNED',
	EmailSent = 'EMAIL_SENT',
	CommentAddedToCustomer = 'COMMENT_ADDED_TO_CUSTOMER',
	CommentAddedToLead = 'COMMENT_ADDED_TO_LEAD',
	InstrumentCreated = 'INSTRUMENT_CREATED',
	InstrumentUpdated = 'INSTRUMENT_UPDATED',
	InstrumentDeleted = 'INSTRUMENT_DELETED',
	CurrencyConversionCreated = 'CURRENCY_CONVERSION_CREATED',
	CurrencyConversionUpdated = 'CURRENCY_CONCERSION_UPDATED',
	CurrencyConversionDeleted = 'CURRENCY_CONVERSION_DELETED',
	CustomerPositionCreated = 'CUSTOMER_POSITION_CREATED',
	CommissionPayoutApproved = 'COMMISSION_PAYOUT_APPROVED',
	CommissionPayoutRejected = 'COMMISSION_PAYOUT_REJECTED',
	CommissionRecordUpdated = 'COMMISSION_RECORD_UPDATED',
	PropTradingWalletCreated = 'PROP_TRADING_WALLET_CREATED',
	PropTradingChallengeCreated = 'PROP_TRADING_CHALLENGE_CREATED',
}

export interface IAuditLogsFiltersDTO {
	actions: Array<string>;
	createdAt: IDateRange | undefined;
	actedAs: UserRole | undefined;
	types: Array<string>;
}

export enum AffectedObjectType {
	Lead = 'LEAD',
	Customer = 'CUSTOMER',
	Instrument = 'INSTRUMENT',
	CurrencyConversion = 'CURRENCY_CONVERSION',
	CommissionPayoutRequest = 'COMMISSION_PAYOUT_REQUEST',
	CommissionLedgerRecord = 'COMMISSION_LEDGER_RECORD',
	Order = 'ORDER',
	Position = 'POSITION',
	PropTrading = 'PROPTRADING',
	Wallet = 'WALLET',
}

export interface IAdminActivity {
	id: string;
	actingCustomerId: string;
	actingCustomerName: string;
	action: AdminActivityAction;
	createdAt: Date;
	role: UserRole;
	type: AffectedObjectType;
	displayName: string;
	affectedObjectId: string;
	affectedObjectFound: boolean;
}

export interface IActivityQuery {
	activities: Array<IAdminActivity>;
	count: number;
}
