import { CCol, CFormGroup, CInput, CInputGroup, CLabel, CRow } from '@coreui/react';
import React, { FormEvent, useState } from 'react';
import { useGetSetting, useSetRecatpchaV3 } from './hooks';
import _t from 'counterpart';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { findErrorFromValidation } from '../../helpers';
import toast from 'react-hot-toast';

interface IState {
	siteKey: string;
	secret: string;
	score: string;
	url: string;
}

const RecaptchaV3Integration = () => {
	const [state, setState] = useState<IState>({
		siteKey: '',
		secret: '',
		score: '',
		url: '',
	});

	const mutation = useSetRecatpchaV3();

	const siteKeyQuery = useGetSetting('CAPTCHA_SITE_KEY', 'captcha_site_key', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setState({ ...state, siteKey: value });
		},
	});

	const secretQuery = useGetSetting('CAPTCHA_SECRET', 'captcha_secret', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setState({ ...state, secret: value });
		},
	});

	const scoreQuery = useGetSetting('CAPTCHA_SCORE', 'captcha_score', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setState({ ...state, score: value });
		},
	});

	const urlQuery = useGetSetting('CAPTCHA_URL', 'captcha_url', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setState({ ...state, url: value });
		},
	});

	const invokeMutation = () => {
		mutation.mutate(state, {
			onSuccess: () => {
				toast.success(_t('recaptchav3.settings-updated-successfully'));
				siteKeyQuery.refetch();
				secretQuery.refetch();
				scoreQuery.refetch();
				urlQuery.refetch();
			},
		});
	};

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(mutation.error, paramName);
		return error;
	};

	const handleInputChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const value = target.value!;

		setState({
			...state,
			[name]: value,
		});
	};

	const allowSave = state.score !== '' && state.secret !== '' && state.siteKey !== '';

	return (
		<CRow>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="url">{_t(`recaptchav3.url`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="url"
							name="url"
							value={state.url}
							onChange={handleInputChange}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{urlQuery.error && <CLabel className="text-danger">{_t(`recaptchav3.failed-url-query`)}</CLabel>}
					{findError('url') && <CLabel className="text-danger">{findError('url')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<CLabel htmlFor="siteKey">{_t(`recaptchav3.site-key`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="siteKey"
							name="siteKey"
							value={state.siteKey}
							onChange={handleInputChange}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{siteKeyQuery.error && <CLabel className="text-danger">{_t(`recaptchav3.failed-site-key`)}</CLabel>}
					{findError('siteKey') && <CLabel className="text-danger">{findError('siteKey')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="secret">{_t(`recaptchav3.secret`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="secret"
							name="secret"
							value={state.secret}
							onChange={handleInputChange}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{secretQuery.error && <CLabel className="text-danger">{_t(`recaptchav3.failed-secret`)}</CLabel>}
					{findError('secret') && <CLabel className="text-danger">{findError('secret')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="score">{_t(`recaptchav3.score`)}</CLabel>
					<CInputGroup>
						<CInput
							type="number"
							id="score"
							name="score"
							value={state.score}
							onChange={handleInputChange}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{scoreQuery.error && <CLabel className="text-danger">{_t(`recaptchav3.failed-score`)}</CLabel>}
					{findError('score') && <CLabel className="text-danger">{findError('score')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12} className="d-flex justify-content-end">
				<ButtonWithLoader
					isLoading={mutation.isLoading}
					onClick={invokeMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t('action.save')}
					className="btn-md"
					showText={false}
					disabled={!allowSave}
				/>
			</CCol>
		</CRow>
	);
};

export default RecaptchaV3Integration;
