import { CSelect } from '@coreui/react';
import React from 'react';
import { useQuery } from 'react-query';
import { IGroup } from '../groups/types';
import { loadGroups } from '../../services/BackendService';
import _t from 'counterpart';

export function ChallengeGroupsSelector(props: { onChange: any; value: string | undefined; currency: string }) {
	const { onChange, value, currency } = props;

	const { isLoading, error, data } = useQuery<Array<IGroup>, Error>(['order-groups'], () => loadGroups());

	if (isLoading) {
		return (
			<CSelect id="nf-group" name="nf-group" disabled>
				<option>{_t('global.loading')}</option>
			</CSelect>
		);
	}

	if (error) {
		return <span>{error.message}</span>;
	}

	if (!value) {
		onChange(data![0]?.name);
	}

	const handleOnChange = (e: any) => {
		onChange(e.target.value);
	};
	return (
		<CSelect id="nf-type" name="nf-type" onChange={handleOnChange} value={value}>
			{data?.map((o: any) => (
				<option value={o?.name} key={o?.name} disabled={currency !== o.depositCurrency}>
					{o?.name} ({o?.depositCurrency})
				</option>
			))}
		</CSelect>
	);
}
